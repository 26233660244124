import {atom, useRecoilState} from 'recoil';
import {useEffect} from "react";
import {authApi} from "../aaxios";

export const chatMessageListState = atom({
    key: 'chatMessageListState', // Atom의 고유 키
    default: [], // 초기값
});

async function getDetailChat(chatId) {
    const requestUrl = `/admin/chat/${chatId}`;
    const res = await authApi.get(requestUrl)
    return res.data['data'];
}

export default function useChatMessageListState(chatId) {
    const [chatMessageList, setChatMessageList] = useRecoilState(chatMessageListState);

    useEffect(() => {
        if (!chatId) return;

        if (!chatId) return;
        console.log("잘나오니> ", chatId)
        getDetailChat(chatId).then(data => {
            setChatMessageList(data['page']);
        });
    }, [chatId, setChatMessageList]);


    function changeBotVectorContent(vectorId, content) {
        const next = chatMessageList.map((cm) => {
            return {
                ...cm,
                bot_messages: cm.bot_messages.map((botMessage) => ({
                    ...botMessage,
                    vector_list: botMessage.vector_list.map((v) =>
                        v.id === vectorId ? {...v, content} : v
                    ),
                })),
            };
        });
        setChatMessageList(next);
    }

    return {
        chatMessageList,
        setChatMessageList,
        changeBotVectorContent
    }
}