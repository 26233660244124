import {useState} from "react";
import Modal from 'react-modal'
import useVector from "../../../hooks/useVector";


const Accordion = ({chatBotMessage, onEdit}) => {
    const {
        vectorList,
        textValues,
        setTextValues,
        onEditVector
    } = useVector({chatBotMessage})

    const [openPanels, setOpenPanels] = useState({
        0: true // 첫번째 패널은 열림
    }); // 각 패널의 열림 상태를 관리하는 객체

    const handleToggle = (index) => {
        setOpenPanels((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // 현재 패널의 상태를 반전
        }));
    };

    const handleTextChange = (index, value) => {
        setTextValues((prevState) => ({
            ...prevState,
            [index]: value, // 해당 패널의 텍스트 값 업데이트
        }));
    };

    return (
        <div>
            {vectorList.map((item, index) => (
                <div key={index} style={{marginBottom: "10px"}}>
                    <div
                        onClick={() => handleToggle(index)}
                        style={{
                            cursor: "pointer",
                            backgroundColor: "#f0f0f0",
                            padding: "10px",
                            border: "1px solid #ddd",
                        }}
                    >
                        답변근거:{index + 1}
                    </div>
                    {openPanels[index] && ( // 각 패널의 열림 상태를 개별적으로 관리
                        <div
                            style={{
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderTop: "none",
                            }}
                        >
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                   <textarea
                                       value={textValues[index] || ""} // 초기 값이 없으면 빈 문자열
                                       onChange={(e) => handleTextChange(index, e.target.value)}
                                       style={{
                                           width: "100%",
                                           height: "100px",
                                           padding: "5px",
                                           border: "1px solid #ccc",
                                           borderRadius: "4px",
                                       }}
                                   />

                                <button
                                    style={{
                                        marginTop: '8px'
                                    }}
                                    onClick={() => onEditVector(index)}
                                    className='button_02'>관리자 답변 수정
                                </button>
                            </div>

                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};


const customModalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        width: "100%",
        height: "100vh",
        zIndex: "10",
        position: "fixed",
        top: "0",
        left: "0",
    },
    content: {
        width: "720px",
        minHeight: "720px",
        zIndex: "150",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white",
        justifyContent: "center",
        overflow: "auto",
    },
};
export default function EditVectorModal({
                                            item,
                                            modalOpen,
                                            setModalOpen,
                                        }) {


    const handleOverlayClick = (e) => {
        // 모달 바깥 클릭 시 닫히게 설정
        if (e.target.id === "modal-overlay") {
            setModalOpen(false);
        }
    };

    const chatBotMessage = item["bot_messages"][0]

    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customModalStyles}
            ariaHideApp={false}
            contentLabel="Pop up Message"
            shouldCloseOnOverlayClick={true} // 바깥 클릭 가능하도록 설정
            overlayClassName="modal-overlay"
            className="modal-content"
            id="modal-overlay"
            onClick={handleOverlayClick} // 클릭 이벤트 핸들러 추가
        >
            <div
                style={{
                    padding: "8px",
                    paddingTop: "16px",
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        marginBottom: "16px",
                        padding: "16px",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        fontFamily: "Arial, sans-serif",
                        color: "#333",
                    }}
                >
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            marginBottom: "8px",
                            color: "#555",
                        }}
                    >
                        답변 결과:
                    </div>
                    <div
                        style={{
                            fontSize: "16px",
                            lineHeight: "1.5",
                            color: "#111",
                        }}
                    >
                        {chatBotMessage["message"]}
                    </div>
                </div>
                <Accordion chatBotMessage={chatBotMessage}/>
            </div>
        </Modal>
    );
}