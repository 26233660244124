import React from "react";
import "../../App.css"


export function TableItem(props) {
    let {
        index,
        id,
        onClick,
        first_message,
        status,
        created_at,
        user,
    } = props

    const dateObject = new Date(created_at);
    const utcToKST = new Date(dateObject.getTime() + 9 * 60 * 60 * 1000); // UTC + 9시간

// 날짜와 시간 분리
    const date = utcToKST.toISOString().split('T')[0]; // "2024-12-02"
    const time = utcToKST.toTimeString().split(' ')[0]


    function handleClick(e) {
        e.stopPropagation()
        console.log("row 클릭")
        onClick && onClick()
    }

    function convertStatus(_status) {
        if (_status === 'unidentified') {
            return "미확인"
        }
        if (_status === 'confirm') {
            return "검토완료"
        }
        if (_status === 'deleted') {
            return '삭제완료'
        }
        if (_status === 'edit') {
            return '수정 필요'
        }
        return "미확인"
    }

    return (
        <tr key={id}
            onClick={handleClick}>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{index + 1}</td>
            <td className='col_width_content' style={{color: 'var(--black, #000)'}}>{first_message}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{date}</td>
            <td className='col_width_basic' style={{color: 'var(--black, #000)'}}>{time}</td>
            <td className='col_width_basic' style={{color: 'var(--red, #DB524C)'}}>{convertStatus(status)}</td>
            <td className='col_width_basic'
                style={{color: 'var(--black, #000)'}}>{user.nickname ? user.nickname : 'X'}</td>
            <td className='col_width_basic'
                style={{color: 'var(--black, #000)'}}>{user.phone_number ? user.phone_number : 'X'}</td>
        </tr>
    )
}


export default function TableBody(props) {
    const {items, onClick} = props;

    return (
        <table style={{width: '95%'}} className='table_frame' id='TableBody'>
            <thead className='TableBodyThead'>
            <tr>
                <th className='col_width_basic'>글번호</th>
                <th className='col_width_content'>내용</th>
                <th className='col_width_basic'>날짜</th>
                <th className='col_width_basic'>시간</th>
                <th className='col_width_basic'>상태</th>
                <th className='col_width_basic'>고객성함</th>
                <th className='col_width_basic'>연락처</th>
            </tr>
            </thead>
            <tbody id='TableBodyTBody'>{
                items.map((item, key) => {
                    return (
                        <TableItem {...item} index={key}
                                   key={key}
                                   onClick={() => onClick({
                                       ...item,
                                       index: key
                                   })}
                        />
                    )
                })
            }</tbody>
        </table>
    )
}