export const TOKEN_STORAGE_NAME = 'vc_tt'


export function setToken(token) {
    localStorage.setItem(TOKEN_STORAGE_NAME + "_access", token['access_token']);
    localStorage.setItem(TOKEN_STORAGE_NAME + "_refresh", token['refresh_token']);
}

export function getToken() {
    const access = localStorage.getItem(TOKEN_STORAGE_NAME + "_access");
    const refresh = localStorage.getItem(TOKEN_STORAGE_NAME + "_refresh");
    if (access==='') return null
    if (refresh==='') return null

    return {
        access, refresh,
    }
}

export function clearToken() {
    localStorage.setItem(TOKEN_STORAGE_NAME + "_access", '');
    localStorage.setItem(TOKEN_STORAGE_NAME + "_refresh", '');
}