import React, {useState} from "react";
import useLoadPdf from "../../hooks/useLoadPdf";
import {useDropzone} from "react-dropzone";
import "../../css/FileUpload.css"

function PdfItemBtn(props) {
    const {item, status, deletePdf} = props

    const doingBtnSty = {
        flex: "0 0 50px",
        margin: '0px 5px 0px 10px',
        backgroundColor: 'transparent',
        color: 'black',
        borderRadius: '5px',
        cursor: 'not-allowed',
        fontWeight: 600
    }
    const removeBtnSty = {
        flex: "0 0 50px",
        margin: '0 5px 0 10px',
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '5px',
        cursor: 'pointer'
    }
    if (status === "STARTED") {
        return (
            <button style={doingBtnSty} disabled>
                <span className="wave-text">학습중</span>
            </button>
        )
    }

    return (
        <button style={removeBtnSty} onClick={() => deletePdf(item)}>
            삭 제
        </button>
    )

}

function PdfItem(props) {
    const {item} = props

    const liSty = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: '5px',
        background: 'white',
        margin: '5px',
        padding: '3px',
        textAlign: 'left',
        borderRadius: '5px'
    }

    const spSty = {
        flex: "1 1 auto",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingLeft: '5px'
    }

    function getStatusImg(status) {
        if (status === 'vectorize_done') {
            return '/img/emb_success.png'
        }
        if (status === 'vectorize_fail') {
            return '/img/emb_failed.png'
        }
        return '/img/emb_loading.gif'
    }

    return (
        <li style={liSty}>
            <span style={spSty}>{item['oring_filename']}</span>
            <img src={getStatusImg(item['status'])}
                 alt="Status Icon" style={{width: '20px', height: '20px'}}/>
            <PdfItemBtn {...props} />
        </li>
    )
}


function PdfFileList(props) {
    const {pdfList} = props
    const sty = {
        fontWeight: 600,
        // maxWidth: '90%',
        width: '90%',
        height: '100%',
        backgroundColor: '#ffb4da82',
        display: 'block',
        padding: '10px',
        marginTop: '5px',
        marginBottom: '10px',
        borderRadius: '10px'
    }
    return (
        <div className='uploaded-data-list' style={sty}>
            <p>업로드한 파일 목록</p>
            <ul style={{paddingLeft: 0}}>
                {pdfList.map(pdf => {
                    return (
                        <PdfItem item={pdf} deletePdf={props.deletePdf}/>
                    )
                })}
            </ul>
        </div>
    )

}


function UploadPdf(props) {
    const [files, setFiles] = useState([]);

    const onDrop = (acceptedFiles) => {
        setFiles([...files, ...acceptedFiles]);
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: "application/pdf", // PDF 파일만 허용
    });


    // 파일 선택 핸들러
    const handleFileChange = (event) => {
        const files = event.target.files;
        setFiles([]);
        const filtered = [...files].filter(file => file.type === "application/pdf")
        setFiles(filtered)
    };
    const handleUpload = async () => {
        if (!files.length) {
            return;
        }

        const formData = new FormData();
        files.map(f => {
            formData.append('files', f);
        })
        const res = await props.onUploadPdf(formData)
        if (res.ok) {
            setFiles([])
        }
    };
    return (
        <div className='home-container'>
            <p className='section-title'>PDF 업로드</p>
            <div className='upload-area'>
                <div className="file-upload-container">
                    <div {...getRootProps({className: "dropzone"})}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>파일을 여기에 드래그하세요...</p>
                        ) : (
                            <p>파일을 드래그하거나 여기를 클릭하여 파일을 선택하세요.</p>
                        )}
                    </div>
                    <div className="file-preview">
                        {files.map((file, index) => (
                            <div key={index} className="file-item">
                                <p>{file.name}</p>
                                <p>({(file.size / 1024).toFixed(2)} KB)</p>
                            </div>
                        ))}
                    </div>
                    <button onClick={handleUpload} className="upload-button">
                        업로드
                    </button>
                </div>


                {/*<input type="file" multiple accept="application/pdf" onChange={handleFileChange}/>*/}
                {/*<button onClick={handleUpload} disabled={files.length === 0}>업로드</button>*/}
            </div>
        </div>
    )
}


export default function PDFContainer(props) {
    const {handleImageClick} = props
    const {uploadPdf, pdfList, deletePdf} = useLoadPdf()


    return (
        <div className='home-container'>
            <div className='title-info-area'>
                <p className='section-title'>PDF List</p>
                <img src={"/img/info_icon.png"}
                     alt="more-info"
                     onClick={() => handleImageClick('/img/manual_01.svg')}/>
            </div>
            <p>동일한 이름의 PDF 파일은 업로드 할 수 없습니다.</p>
            <p>기존 파일 삭제 후 업로드 해주시기 바랍니다.</p>
            <PdfFileList pdfList={pdfList} deletePdf={deletePdf}/>
            <UploadPdf onUploadPdf={uploadPdf}/>
        </div>
    )
}