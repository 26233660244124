import {useEffect, useState} from "react";
import {authApi} from "../aaxios";
import useChatMessageListState from "../recoil/useChatMessageListState";

function reduceContents(items) {
    console.log('reduce>> ', items)
    return items.reduce((acc, item, index) => {
        acc[index] = item['content'];
        return acc;
    }, {})
}

async function regenVectorRequest({
                                      vectorId,
                                      content
                                  }) {
    try {
        const url = `/admin/regen-embedding`
        const res = await authApi.put(url, {
            "id": vectorId,
            content
        })
        return res['data']['data']
    } catch (e) {
        console.log(e)
    }
}

export default function useVector({
                                      chatBotMessage
                                  }) {
    const {
        changeBotVectorContent,

    } = useChatMessageListState()
    const [vectorList, setVectorList] = useState([])
    const [textValues, setTextValues] = useState({});
    useEffect(() => {
        if (chatBotMessage['vector_list']) {
            setVectorList(chatBotMessage['vector_list'])
            setTextValues(reduceContents(chatBotMessage['vector_list']))
        }
    }, [chatBotMessage['id']]);

    async function onEditVector(index) {
        const vector = vectorList[index]
        const changedContent = textValues[index]
        if (changedContent !== vector['content']) {
            const result = await regenVectorRequest({
                "vectorId": vector.id,
                "content": changedContent
            })
            if (result && result['id']) {
                const newVectorList = [...vectorList]
                newVectorList[index] = result
                setVectorList(newVectorList)
                changeBotVectorContent(vector.id, changedContent)
                alert("변경 완료 되었습니다.")
            }
        }

    }

    return {
        vectorList,
        textValues,
        setTextValues,
        onEditVector
    }
}