import {useEffect, useState} from "react";
import {authApi} from "../aaxios";
import useChatMessageListState from "../recoil/useChatMessageListState";


async function getDetailChat(chatId) {
    const requestUrl = `/admin/chat/${chatId}`;
    const res = await authApi.get(requestUrl)
    return res.data['data'];
}

async function changeStatusReq({
                                   chat_id,
                                   status
                               }) {
    /*
    *     UNIDENTIFIED = "unidentified"  # default
    CONFIRM = "confirm"
    DELETED = "deleted"
    EDIT = "edit"

    * */
    try {
        const url = `/admin/chat/${chat_id}/status`
        const res = await authApi.put(url, {
            status
        })
        return res['data']
    } catch (e) {
        console.log(e)
    }
}


export default function useLoadChat({chatId}) {
    const {chatMessageList} =
        useChatMessageListState(chatId)
    
    async function onChangeStatus(status) {

        const res = await changeStatusReq({
            chat_id: chatId,
            status
        })
        return res
    }

    return {
        chatMessageList,

        onChangeStatus
    }
}